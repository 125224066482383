
import react, { Component, useState } from 'react'
import Header from "./header"
import BasicGrid from './Grid'
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import Page from './card/page/page'
import About from './card/about'
import Admin from './card/admin';
import Search from './Search';
import Category from './Category';
import { Helmet } from 'react-helmet';

function App() {

  

  const [input, setinput] = useState("")
  return <>
  <Helmet> <title>Home page 4khdhub </title>
  <meta name='description' content='Home page of 4khdhub' ></meta></Helmet>
    <Header />
    <Routes>
      <Route path="/page" element={<Page />}></Route>
      <Route path="/" element={<BasicGrid />} />
      <Route path="/about" element={<About />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/search" element={<Search />} />
      <Route path="/:category" element={<Category />} />
    </Routes>
  </>

}
// this is a comment now he use helmet for seo :)

export default App
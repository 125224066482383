import React, { useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { CircularProgress, TextField } from "@mui/material";
import moment from "moment";
import Card from "./card/card";
import { Helmet } from "react-helmet";

export default function Search() {
    const [panding, done] = React.useState(0);
    const [unset, set] = React.useState(0);
    const [crd, setcrd] = React.useState("");
    const [dp, setdp] = React.useState("");
    const [find, setfind] = React.useState(0);
    const [da, setda] = React.useState("");

    async function geteuser() {
        const url = 'https://fourkhdhub.onrender.com/search';
        try {
            const response = await axios.get(url);
            const get = response.data;

            setcrd(get);
            if (get !== null) {
                set(1);
                setdp("none");
            } else {
                setfind(1);
            }
        } catch (errr) {
            console.log(errr);
        }
    }

    async function query() {
        done(1);
        const url = 'https://fourkhdhub.onrender.com/search';
        await axios.post(url, { da }).then(() => {
            console.log("data is posted");
            geteuser();
        });
    }

    function reader(e) {
        const val = e.target.value;
        if (val.length) {
            setda(val);
        }
    }

    return (
        <>
            <Helmet>
                <title>Search page | 4khdhub Movies</title>
                <meta name="description" content="Search page of 4khdhub movies " />
                <meta name="keywords" content="hd Movies download | 4khdhub movies | bollywood movies download " />
            </Helmet>

            <div id="Searchbar" style={{ position: "absolute", textAlign: "center", color: "white", fontFamily: "cursive", lineHeight: "1", display: dp }}>
                {!panding ?
                    <div>
                        <h1> ---Search box--- </h1>
                        <div>
                            <SearchIcon sx={{ backgroundColor: "white", color: "black", fontSize: "2rem" }} />
                            <TextField
                                id="standard-basic"
                                variant="standard"
                                focused
                                autoComplete="off"
                                required
                                placeholder="Search...."
                                onChange={reader}
                                sx={{ color: 'white', backgroundColor: "white", marginLeft: "2%" }}
                            />
                        </div>
                        <button id="submitButtonId" onClick={query} style={{ backgroundColor: "#205295", color: "white", padding: "2%", margin: "5%" }}>SEARCH</button>
                        <ul>
                            <li>Copy movie Name from Google To Get Better Result :)</li>
                            <li>Don't Add space in Last</li>
                            <li>Keep Searching and Keep Downloading :)</li>
                        </ul>
                    </div> :
                    <CircularProgress />
                }
            </div>

            <div style={{ width: "30%" }}>
                {unset && (
                    <div>
                        <Card
                            imagelogo={crd.imagelogo}
                            moviename={crd.moviename}
                            date={moment(crd.createdAt).fromNow()}
                            s1={crd.sam1}
                            s2={crd.sam2}
                            s3={crd.sam3}
                            s4={crd.sam4}
                            dw={crd.Download}
                            discription={crd.discription}
                            tags={crd.tags}
                        />
                    </div>
                )}

                {find && (
                    <div style={{ position: "absolute", color: "white" }}>
                        <h1>NOT FOUND Try Again :(</h1>
                        <button onClick={() => {
                            done(0);
                            setfind(0);
                        }}>Click here to Retry</button>
                    </div>
                )}
            </div>
        </>
    );
}

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Container } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from "./card/card"
import moment from 'moment';
import { CircularProgress } from '@mui/material';


import axios from "axios"
import { json } from 'react-router-dom';
const url = 'https://fourkhdhub.onrender.com/done';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicGrid() {
  const [mdata, setdata] = React.useState("")
  const [render ,setrender]=React.useState(1)
  React.useEffect(() => {
    const response = fetch('https://fourkhdhub.onrender.com/')
    console.log(response);
    async function getuser() {
      try {
        const response = await axios.get(url).then(function (response) {
          const data = response.data;
          setdata(data);
          setrender(0)
        });
      } catch (error) {
        console.error(error);
      }
    }
    getuser()
  }, [])



  const add = {

    color: "blue",
    border: "5px solid black",
    marginTop: "10px"
  };


  return  <Box> {!render?<Grid container spacing={2} >
        {Array.from(mdata).map((data, i) => {
          return <Grid item xs={6} sm={3} key={i}>

            <Card date={moment(data.createdAt).fromNow()} s1={data.sam1} s2={data.sam2} s3={data.sam3} s4={data.sam4} dw={data.Download} imagelogo={data.imagelogo} moviename={data.moviename} discription={data.discription} tags={data.tags} key={i} />
          </Grid>
        })}

      </Grid>:<CircularProgress style={{position:"relative",left:"45%",marginTop:"25%"}} />}
    </Box>
}
